const adp = {
  title: "ADP",
  type: "HR",
  img: "adp.png",
};
const craftable = {
  title: "Craftable",
  type: "Inventory",
  img: "craftable.png",
};
const cheftab = {
  title: "ChefTab",
  type: "Networking",
  img: "cheftab.png",
};
const compeat = {
  title: "Compeat",
  type: "Management",
  img: "compeat.png",
};
const ddd = {
  title: "DoorDash Drive",
  type: "Online Ordering",
  img: "doordash-drive.png",
};
const rasi = {
  title: "RASI",
  type: "Accounting",
  img: "rasi.png",
};
const ctuit = {
  title: "CTUIT",
  type: "Management",
  img: "ctuit.png",
};
const dolce = {
  title: "Dolce",
  type: "Labor/Gratuity",
  img: "dolce.png",
};
const gratshare = {
  title: "GratShare",
  type: "Gratuities",
  img: "gratshare.png",
};
const gusto = {
  title: "Gusto",
  type: "Payroll",
  img: "gusto.png",
};
const hotSchedules = {
  title: "HotSchedules",
  type: "Scheduling",
  img: "hot-schedules.png",
};
const inKind = {
  title: "inKind",
  type: "Payment",
  img: "in-kind.png",
};
const istaCheckmate = {
  title: "ItsaCheckmate",
  type: "Online ordering",
  img: "itsa.png",
};
// const paycom = {
//   title: "Paycom",
//   type: "Payroll",
//   img: "paycom.png",
// };
const qrsAutomation = {
  title: "QSR Automation",
  type: "Management",
  img: "qsr.png",
};
const qrsOnline = {
  title: "QSR Online",
  type: "Management",
  img: "qsr-online.png",
};
const quickbooks = {
  title: "QuickBooks Online",
  type: "Accounting",
  img: "quickbooks.png",
};
const restaurants365 = {
  title: "Restaurant 365",
  type: "Management",
  img: "restaurant-365.png",
};
const safeSurv = {
  title: "SafeSurv",
  type: "Beverage Management",
  img: "safe-surv.png",
};
const sculpture = {
  title: "Sculpture Hospitality",
  type: "Inventory",
  img: "sculpture.png",
};
const paycor = {
  title: "Paycor",
  type: "Management",
  img: "paycor.jpg",
};
const paylocity = {
  title: "Paylocity",
  type: "HR",
  img: "paylocity.png",
};
const shippo = {
  title: "Shippo",
  type: "eCommerce shipping",
  img: "shippo.png",
};
const paypal = {
  title: "Paypal & Venmo",
  type: "Digital Wallet",
  img: "paypal.png",
};
const doorDash = {
  title: "DoorDash",
  type: "Local Delivery",
  img: "door-dash.png",
};
const davo = {
  title: "DAVO",
  type: "Tax Management",
  img: "davo.png",
};
const marginEdge = {
  title: "MarginEdge",
  type: "Management",
  img: "margin.png",
};
const bevchek = {
  title: "Bevchek",
  type: "Inventory",
  img: "bevcheck.png",
};

const beerSAVER = {
  title: "US BeerSAVER",
  type: "Inventory",
  img: "beersaver.png",
};
const tenzo = {
  title: "Tenzo",
  type: "Management",
  img: "Tenzo_logo.png",
};

// eslint-disable-next-line import/prefer-default-export
export const restaurantData = [
  craftable,
  istaCheckmate,
  gratshare,
  gusto,
  ddd,
  qrsOnline,
  rasi,
  qrsAutomation,
  ctuit,
  hotSchedules,
  sculpture,
  compeat,
  quickbooks,
  safeSurv,
  adp,
  dolce,
  cheftab,
  inKind,
  // paycom,
  restaurants365,
  paycor,
  paylocity,
  marginEdge,
  davo,
  bevchek,
  tenzo,
  // beerSAVER,
];

export const retailData = [shippo, paypal, doorDash];
