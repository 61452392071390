import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import {
  retailOverviewHero,
  retailOverviewWhiteGlove,
  retailOverviewMultiSection,
  retailOverviewCTA,
  retailOverviewVideo,
  retailOverviewTestimonials,
  retailOverviewArticles,
  // retailOverviewFaqs,
  retailOverviewSubverticals,
  retailOverviewPartIntegration,
  retailOverviewTrusted,
  retailIntegrations,
} from "../data/subverticals/retail/retail-overview-data";
import { retailData } from "../data/integration-data";
import {
  heroSuccess,
  uniquePlantsAndPalmsSlides,
} from "../data/success-stories-data";
import Hero from "../components/Hero/hero";
// import WhiteGlove from "../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../components/LargeFeatures/LargeFeatures";
// import LargeCta from "../components/CTAs/LargeCta";
// import SuccessStories from "../components/SuccessStories/SuccessStories";
// import VideoSection from "../components/VideoSection/VideoSection";
// import TestmonialReviews from "../components/TestimonialReviews/TestmonialReviews";
// import Subverticals from "../components/SubVerticals/Subverticals";
// import TrustedNumbers from "../components/TrustedNumbers/TrustedNumbers";
// import IntegrationsSection from "../components/Integrations/IntegrationsSection";
// import Articles from "../components/Articles/Articles";

import ogImage from "../images/global_assets/og-image.png";

const WhiteGlove = loadable(() =>
  import("../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../components/TestimonialReviews/TestmonialReviews")
);
const Subverticals = loadable(() =>
  import("../components/SubVerticals/Subverticals")
);
const TrustedNumbers = loadable(() =>
  import("../components/TrustedNumbers/TrustedNumbers")
);
const IntegrationsSection = loadable(() =>
  import("../components/Integrations/IntegrationsSection")
);
const Articles = loadable(() => import("../components/Articles/Articles"));
// const CapterraBanner = loadable(() =>
//   import("../components-v2/CapterraBanner")
// );

const whiteGloveImport = "retail-white-glove.png";

const Retail = () => {
  return (
    <Layout>
      <SEO
        title="Retail POS Systems & Software for Businesses | SpotOn"
        description="SpotOn is the best retail point of sale system available. Our system allows you to sell in-store & online through one complete system."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={retailOverviewHero} circleBg={false} />
      <TrustedNumbers numbersArray={retailOverviewTrusted} />
      <WhiteGlove
        sectionData={retailOverviewWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <Subverticals sectionData={retailOverviewSubverticals} />
      <LargeFeatures
        sectionData={retailOverviewMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <VideoSection sectionData={retailOverviewVideo} />
      <IntegrationsSection
        sectionData={retailIntegrations}
        integrationList={retailData}
        showInt
        isRetail
      />
      <SuccessStories
        sectionData={heroSuccess}
        slides={uniquePlantsAndPalmsSlides}
      />
      <IntegrationsSection sectionData={retailOverviewPartIntegration} />
      {/* <CapterraBanner
        className="mt-16 lg:mt-20 mb-20 lg:mb-40"
        imageName="capterra-retail-pos.png"
        title={`Rated the <span class="text-primary">best</span> retail POS by real users<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See the results"
        ctaTarget="https://datainsights.capterra.com/p/retail-pos-system/226928/spoton/references?g=na&c=&c=&c=&sc=6322276"
      /> */}
      <LargeCta sectionData={retailOverviewCTA} />
      <TestmonialReviews sectionData={retailOverviewTestimonials} />
      <section style={{ marginTop: 60, marginBottom: 80 }}>
        <Articles
          sectionData={retailOverviewArticles}
          blogFilters={{
            limit: 3,
            include: "tags,authors",
            filter: "tag:small-business",
          }}
          cornerStone={false}
        />
      </section>
      {/* <PartnerLogos />
      <ByTheNumbers sectionData={retailByTheNumbers} /> */}
      {/* <Faqs sectionData={retailOverviewFaqs} /> */}
      {/*      <Articles
        sectionData={retailOverviewArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default Retail;
